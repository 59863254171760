import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AshGuidesModes: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Game Modes Overview</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_modes.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Game Modes</h1>
          <h2>All game modes available in Ash Echoes explained!</h2>
          <p>
            Last updated: <strong>02/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Intro" />
        <p>
          Ash Echoes has a variety of modes, each offering different gameplay
          and rewards. This guide will go over all of them!
        </p>
        <SectionHeader title="World" />
        <p>
          World is the main story mode of Ash Echoes, consisting of combat
          encounters broken up by visual novel sections. Completing stages for
          the first time will award X-Particles, XP, and materials to upgrade
          the Dome. After clearing a story episode you will be rewarded with a
          SSR Memory Trace and Elite mode will become accessible. These are a
          set of more challenging stages that offer more X-Particles when
          cleared, as well as a dupe of the Memory Trace.
        </p>
        <SectionHeader title="Event Stories" />
        <p>
          Event stories are time-limited and involve standard battles, unique
          minigames, and boss challenges. Participation in events will reward
          shop currency to obtain various valuable items, such as Memory Traces,
          Resonance Clues, and skill upgrade materials.
        </p>
        {/* <StaticImage
          src="../../../images/ash/generic/modes_1.webp"
          alt="Guides"
        /> */}
        <SectionHeader title="Echoing Nexus" />
        <p>
          Echoing Nexus is a roguelite game mode where the player selects a
          character and Memory Traces to progress through a series of
          increasingly difficult encounters. As you progress, you will be
          offered stat increases and skills based on the Memory Traces being
          used. Upon completing an Echoing Nexus run, stats and skills gained
          will be saved as an Engraving which can be used in other game modes.
        </p>
        <p>
          Echoing Nexus is core to progression in Ash Echoes, as your Engravings
          dictate your stats and skills. In addition to Engravings, completing
          zones in Echoing Nexus for the first time will reward you with
          X-Particles. Clearing a certain cumulative depth in a week will also
          reward Mirrorshards and Coins.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_2.webp"
          alt="Guides"
        />
        <p>For more on Echoing Nexus, check out our guide below!</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Echoing Nexus Overview"
            link="/ash-echoes/guides/echoing-nexus"
            image={
              <StaticImage
                src="../../../images/ash/categories/category_nexus.webp"
                alt="Echoing Nexus Overview"
              />
            }
          />
        </Row>
        <SectionHeader title="Nexus Memory Challenges" />
        <p>
          Each path in Echoing Nexus has a series of challenges that can be done
          after reaching certain Engraving ratings on that path. These are
          one-off battles that provide rewards such as X-Particles and Memory
          Trace selectors once cleared.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_3.webp"
          alt="Guides"
        />
        <SectionHeader title="Field-Ops" />
        <p>
          Supply Assurance, Free Trade, Revelation Operation, Light Protocol,
          and Neobrium Domain can be found in Field Ops and all involve
          participating in single battles to obtain resources used to level up
          characters, skills, and Memory Traces.
        </p>
        <ul>
          <li>
            <strong>Supply Assurance</strong>: Provides materials for character
            ascension and skill upgrades.
          </li>
          <li>
            <strong>Free Trade</strong>: Provides coins.
          </li>
          <li>
            <strong>Revelation Operation</strong>: Provides Mirrorshards to
            level up Memory Traces.
          </li>
          <li>
            <strong>Light Protocol</strong>: Provides chips to upgrade skills.
          </li>

          <li>
            <strong>Neobrium Domain</strong>: Provides materials for increasing
            the level cap of Memory Traces.
          </li>
        </ul>
        <SectionHeader title="Skyrift Observation" />
        <p>
          Skyrift Observation consists of four paths of six one-off battles with
          unique modifiers providing buffs and debuffs. Each path has designated
          classes that will receive bonuses for stages in the path. Clearing
          Skyrift Observation grants X-Particles and XP.
        </p>
        <p>
          After clearing a path, Deep Observation will be unlocked. This
          contains four more missions that reset periodically, giving more
          X-Particles.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Path to Proficiency" />
        <p>
          After clearing a path in Skyrift Observation, Path to Proficiency will
          become available. Paths for different classes will be open on
          different days, each with different buffs. Path of Proficiency
          requires a special entry currency to be accessed. Only two characters
          can be brought to a stage.
        </p>

        <StaticImage
          src="../../../images/ash/generic/modes_5.webp"
          alt="Guides"
        />
        <p>
          Clearing stages in Path to Proficiency rewards Badges of Origins,
          which can be used to upgrade a character’s Potential. Clearing certain
          milestones with specific characters will yield that character’s
          Crystaplates, also used for upgrading Potential. Clearing the 10th
          stage with a character will give a special profile picture of the
          character with a golden frame.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_6.webp"
          alt="Guides"
        />
        <SectionHeader title="Joint Training" />
        <p>
          Joint Training is a monthly boss challenge. Battles can be done in
          four defense zones to station characters, with each zone occupied
          allowing the boss difficulty to be increased further. A boss
          difficulty can then be selected, with higher difficulties yielding
          higher scores. X-Particles will be rewarded for clearing up to a
          certain difficulty per week, with additional X-Particles being awarded
          based on rank.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_7.webp"
          alt="Guides"
        />
        <SectionHeader title="Tea Time" />
        <p>
          In Tea Time, players can customize a room with various decorations to
          increase a Coziness level. As Coziness increases, the Bond points and
          Furniture Vouchers obtained from doing Tea Times will also increase.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_8.webp"
          alt="Guides"
        />
        <p>
          Up to three characters a day can be invited to Tea Time, during which
          a drink can be made, providing more bond points if the character likes
          the ingredients used. Making specific drinks will also unlock Memoir
          Achievements. Characters can be invited additional times with the use
          of Universal Invitations.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_9.webp"
          alt="Guides"
        />
        <p>
          Additionally, baseball, billiards and darts can be played as minigames
          in Tea Time.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tea Time Drinks"
            link="/ash-echoes/tea-time"
            image={
              <StaticImage
                src="../../../images/ash/categories/category_tea.webp"
                alt="Tea Time Drinks"
              />
            }
          />
        </Row>
        <SectionHeader title="S.E.E.D Dome" />
        <p>
          S.E.E.D Dome consists of Basic R&D, the Training Room, and the
          Processing Center. Basic R&D produces materials used in the Training
          Room, where characters can upgrade their stat bonuses.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_10.webp"
          alt="Guides"
        />

        <p>
          The processing center allows for chips and ascension materials to be
          crafted out of lower rank materials.
        </p>
        <StaticImage
          src="../../../images/ash/generic/modes_11.webp"
          alt="Guides"
        />

        <p>
          As you progress the main story, you will be able to upgrade the
          S.E.E.D Dome to increase the efficiency of its functions and improve
          the Terminal during combat.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesModes;

export const Head: React.FC = () => (
  <Seo
    title="Game Modes | Ash Echoes | Prydwen Institute"
    description="All game modes available in Ash Echoes explained!"
    game="ash"
  />
);
